.prize-image {
    max-width: 800px;
    max-height: 400px;
}

.raffle-viewer-container {

    .viewer-content {
        width: 100vw;
        height: 100vh;

        .background-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
        }

        .prize-content {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            text-align: center;

            .prize-img {
                width: 50%;
                height: 50%;
            }

            .prize-label {
                color: #fff;
                font-size: 6rem;
                font-weight: 700;
                width: 100%;
            }

            .prize-desc {
                color: #fff;
                font-size: 3rem;
                font-weight: 700;
                width: 100%;
            }
        }

        .winner-content {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .winner-text {
                color: #fff;
                font-size: 5rem;
                font-weight: 700;
                animation: 3s slide-right;
                width: 100%;
                text-align: center;
            }

            .winner-name {
                color: #fff;
                font-size: 9rem;
                font-weight: 700;
                animation: 3s slide-left;
                width: 100%;
                text-align: center;
            }
        }
    }
}



@keyframes slide-left {
    from {
        margin-left: 100%;
    }

    to {
        margin-left: 0%;
    }
}

@keyframes slide-right {
    from {
        margin-right: 100%;
    }

    to {
        margin-right: 0%;
    }
}